import { Component, OnInit } from "@angular/core";
import { PageLoaderService } from "../page-loader.service";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";

import { DeviceDetectorService } from "ngx-device-detector";

@Component({
  selector: "Card",
  templateUrl: "./card.component.html",
  styleUrls: ["./card.component.scss"],
})
export class CardComponent implements OnInit {
  pageLoder: boolean;
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  deviceInfo: any;
  constructor(
    private load: PageLoaderService,
    private translate: TranslateService,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    this.deviceInfo = this.deviceService.getDeviceInfo();
  }
}
