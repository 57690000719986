import { Component, OnInit } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import $ from "jquery";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  lang: string;
  supportMail: string;
  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
      switch (event.lang) {
        case "it":
          this.supportMail = "thehub_ee@stellantis.com";
          break;
        case "bp":
          this.supportMail = "thehub_ee@stellantis.com";
          break;
        case "es":
          this.supportMail = "thehub_ee@stellantis.com";
          break;
        case "fr":
          this.supportMail = "thehub_ee@stellantis.com";
          break;
        case "pl":
          this.supportMail = "thehub_ee@stellantis.com";
          break;
        case "sr":
          this.supportMail = "thehub_ee@stellantis.com";
          break;
        default:
          this.supportMail = "thehub_ee@stellantis.com";
      }
    });
  }

  ngOnInit() {
    localStorage.clear();
    $(".page-container.desktop").removeClass("desktop");
    $("a.collapsable-toggler").on("click", function () {
      $(this)
        .closest(".collapsable")
        .toggleClass("open")
        .find(".collapsable-content")
        .slideToggle(100);
    });
    this.lang = this.translate.currentLang.toUpperCase();
  }

  showBackupCode() {
    window.open(
      "../../assets/pdf/Generazione_backup_code_per_la_Verifica_a_2_Fattori.pdf"
    );
  }

  showInfo() {
    window.open("../../assets/pdf/TheIDHub_Tutorial.pdf", "_blank");
  }

  showInfoPhase2() {
    window.open("../../assets/pdf/TheIdHUB_Tutorial_Fase2.pdf", "_blank");
  }
}
