import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { APIService } from "../api.service";
import * as _ from "lodash";
import { FormGroup, FormControl } from "@angular/forms";
import { RedirectService } from "../redirect.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { TranslateService } from "@ngx-translate/core";
import { PageLoaderService } from "../page-loader.service";

@Component({
  selector: "app-security-questions",
  templateUrl: "./security-questions.component.html",
  styleUrls: ["./security-questions.component.scss"],
})
export class SecurityQuestionsComponent implements OnInit {
  name: string = "";
  firstQuest: string;
  firstResp: string;
  secondQuest: string;
  secondResp: string;
  securityForm = new FormGroup({
    firstQuest: new FormControl(""),
    firstResp: new FormControl(""),
    secondQuest: new FormControl(""),
    secondResp: new FormControl(""),
  });
  actived: boolean;
  errorFirst: boolean;
  errorSecond: boolean;
  errorSet: boolean;
  errorLengthfirstQuest: boolean;
  errorLengthfirstResp: boolean;
  errorLengthsecondQuest: boolean;
  errorLengthsecondResp: boolean;
  isMobile: any;
  isTablet: any;
  isDesktop: any;
  pageLoader: any = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private API: APIService,
    private redirect: RedirectService,
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private load: PageLoaderService
  ) {}

  async ngOnInit() {
    this.load.change(this.pageLoader);
    setTimeout(() => {
      if (!_.isEmpty(this.translate.store.translations)) {
        this.pageLoader = true;
        this.load.change(this.pageLoader);
      }
    }, 1000);
    let quest = JSON.parse(localStorage.getItem("quest"));
    if (quest) {
      this.firstQuest = quest.firstQuest;
      this.firstResp = quest.firstResp;
      this.secondQuest = quest.secondQuest;
      this.secondResp = quest.secondResp;
      this.actived = true;
    } else {
      this.firstQuest = "";
      this.firstResp = "";
      this.secondQuest = "";
      this.secondResp = "";
    }
    this.errorFirst = false;
    this.errorSecond = false;
    this.errorSet = false;
    this.errorLengthfirstResp = false;
    this.errorLengthsecondResp = false;
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktop = this.deviceService.isDesktop();
    if (localStorage.getItem("token")) {
      let resp = await this.API.getMe();
      if (resp.status === 200) {
        this.API.setUsername(
          resp.data.username,
          `${resp.data.firstName
            .charAt(0)
            .toUpperCase()}${resp.data.lastName.charAt(0).toUpperCase()}`
        );
        this.name = resp.data.firstName;
        if (resp.data.status !== 30) {
          this.redirect.redirect(resp.data.status);
        }
      }
    } else {
      this.router.navigate(["/login"]);
    }
  }

  validateResp(quest, resp) {
    var words = _.words(resp);
    let valid = true;
    for (let i = 0; valid === true && i < words.length; i = i + 1) {
      let word = words[i].toLowerCase();
      let checkWord = quest.toLowerCase().indexOf(word);
      if (checkWord > -1) {
        valid = false;
      }
    }
    return valid;
  }

  activedButton() {
    if (
      this.firstQuest !== "" &&
      this.firstResp !== "" &&
      this.secondQuest !== "" &&
      this.secondResp !== "" &&
      this.firstResp.length >= 8 &&
      this.secondResp.length >= 8 &&
      this.errorFirst === false &&
      this.errorSecond === false &&
      this.errorSet === false &&
      this.errorLengthfirstResp === false &&
      this.errorLengthsecondResp === false
    ) {
      this.actived = true;
    } else {
      this.actived = false;
    }
  }

  onChangeValue(e) {
    this[e.target.id] = e.target.value.trim();
    this.activedButton();
  }

  respControll(e) {
    this.onChangeValue(e);
    this.errorSet = false;
    this["errorLength" + e.target.id] = false;
    if (this.firstQuest !== "" && this.firstResp !== "") {
      this.errorFirst = !this.validateResp(this.firstQuest, this.firstResp);
    } else {
      this.errorFirst = false;
    }
    if (this.secondQuest !== "" && this.secondResp !== "") {
      this.errorSecond = !this.validateResp(this.secondQuest, this.secondResp);
    } else {
      this.errorSecond = false;
    }
    if (
      (this.firstQuest !== "" &&
        this.secondQuest !== "" &&
        this.firstQuest === this.secondQuest) ||
      (this.firstResp !== "" &&
        this.secondResp !== "" &&
        this.firstResp === this.secondResp)
    ) {
      this.errorSet = true;
    }
    if (this.firstResp !== "" && this.firstResp.length < 8) {
      this.errorLengthfirstResp = true;
    }
    if (this.secondResp !== "" && this.secondResp.length < 8) {
      this.errorLengthsecondResp = true;
    }
    this.activedButton();
  }

  sendSecurityQuest() {
    let quest = {
      firstQuest: this.firstQuest,
      firstResp: this.firstResp,
      secondQuest: this.secondQuest,
      secondResp: this.secondResp,
    };
    let stringQuest = JSON.stringify(quest);
    localStorage.setItem("quest", stringQuest);
    this.router.navigate(["new-password"]);
  }
}
