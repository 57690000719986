import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PersonalEmailComponent } from "./personal-email/personal-email.component";
import { FirstLoginComponent } from "./first-login/first-login.component";
import { MailSendComponent } from "./mail-send/mail-send.component";
import { VerifiedMailComponent } from "./verified-mail/verified-mail.component";
import { NewPasswordComponent } from "./new-password/new-password.component";
import { DataCheckComponent } from "./data-check/data-check.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { AdminPanelComponent } from "./admin-panel/admin-panel.component";
import { LoginAdminComponent } from "./login-admin/login-admin.component";
import { LandingComponent } from "./landing/landing.component";
import { ResetMailComponent } from "./reset-mail/reset-mail.component";

const routes: Routes = [
  { path: "", component: LandingComponent },
  { path: "login", component: FirstLoginComponent },
  { path: "personal-email", component: PersonalEmailComponent },
  { path: "mail-send", component: MailSendComponent },
  { path: "verified-mail", component: VerifiedMailComponent },
  { path: "new-password", component: NewPasswordComponent },
  { path: "data-check", component: DataCheckComponent },
  { path: "error-page", component: ErrorPageComponent },
  { path: "admin", component: AdminPanelComponent },
  { path: "admin/auth", component: LoginAdminComponent },
  { path: "admin/reset-mail", component: ResetMailComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
