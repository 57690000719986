// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // CONFIG C.HI
  //SITEKEY:"6LelP6MZAAAAAHoiS3u5652URSnZP_Fdx3tKEAni",
  //PROVIDER:"284664331439-9tk7o974cko6ap5h5up9cjgbpdfdkt4h.apps.googleusercontent.com"

  // CONFIG TEST
  // SITEKEY: "6LdYXrsZAAAAADU3iTtrDkNJFEZNcdUjhqClvQYV",
  // PROVIDER:"250220441855-sdamrlb4gsaj9gc9h04j8fa4rplp0dfq.apps.googleusercontent.com",

  // CONFIG PROD
  SITEKEY: "6LfctroZAAAAAJ36e5KEPcOL7_1w9cbnibsduI-g",

  PROVIDER:
    "385330089444-9c45rca3mpu4aiinu0u7a52mm85ek7kt.apps.googleusercontent.com",

  URL: "https://api-dot-idhub-comau-prod.ey.r.appspot.com",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
