import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { NgxUiLoaderModule, NgxUiLoaderConfig } from "ngx-ui-loader";
import { NgZorroAntdModule } from "ng-zorro-antd";
import {
  DeviceDetectorModule,
  DeviceDetectorService,
} from "ngx-device-detector";
import { MaterialModule } from "./material-modules";
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { FirstLoginComponent } from "./first-login/first-login.component";
import { PersonalEmailComponent } from "./personal-email/personal-email.component";
import { MailSendComponent } from "./mail-send/mail-send.component";
import { VerifiedMailComponent } from "./verified-mail/verified-mail.component";
import { SecurityQuestionsComponent } from "./security-questions/security-questions.component";
import { NewPasswordComponent } from "./new-password/new-password.component";
import { DataCheckComponent } from "./data-check/data-check.component";
import { AdminPanelComponent } from "./admin-panel/admin-panel.component";
import { ErrorPageComponent } from "./error-page/error-page.component";
import { LoginAdminComponent } from "./login-admin/login-admin.component";
import { LandingComponent } from "./landing/landing.component";
import { ResetMailComponent } from "./reset-mail/reset-mail.component";

import { environment } from "../environments/environment";

import {
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaSettings,
  RecaptchaV3Module,
} from "ng-recaptcha";
import { CardComponent } from "./card/card.component";

import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "#597dda",
  bgsOpacity: 0.1,
  bgsPosition: "bottom-right",
  bgsSize: 40,
  bgsType: "ball-spin-clockwise",
  blur: 5,
  delay: 0,
  fastFadeOut: true,
  fgsColor: "#597dda",
  fgsPosition: "center-center",
  fgsSize: 50,
  fgsType: "three-bounce",
  gap: 24,
  masterLoaderId: "master",
  overlayBorderRadius: "0",
  overlayColor: "transparent",
  pbColor: "red",
  pbDirection: "ltr",
  pbThickness: 3,
  hasProgressBar: false,
  text: "",
  textColor: "#FFFFFF",
  textPosition: "center-center",
  maxTime: -1,
  minTime: 300,
};

const siteKey = environment.SITEKEY;
const provider = environment.PROVIDER;

const globalSettings: RecaptchaSettings = {
  siteKey: siteKey,
};

let configSocialLogin = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(provider),
  },
]);

export function provideConfig() {
  return configSocialLogin;
}

@NgModule({
  declarations: [
    AppComponent,
    CardComponent,
    FirstLoginComponent,
    PersonalEmailComponent,
    MailSendComponent,
    VerifiedMailComponent,
    SecurityQuestionsComponent,
    NewPasswordComponent,
    DataCheckComponent,
    ErrorPageComponent,
    AdminPanelComponent,
    LoginAdminComponent,
    ResetMailComponent,
    LandingComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SocialLoginModule,
    ReactiveFormsModule,
    HttpClientModule,
    RecaptchaV3Module,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    DeviceDetectorService,

    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LfctroZAAAAAJ36e5KEPcOL7_1w9cbnibsduI-g",
    },

    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
